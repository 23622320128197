import React from "react";
import { createBrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./context/auth-context";
import { BookingFlowController } from "./context/booking-flow-context";
import { BookingCancelled } from "./pages/BookingCancelled";
import { BookingFlowPage } from "./pages/BookingFlowPage";
import BookingLayoutWrapper from "./pages/BookingLayoutWrapper";
import BookingPaymentByLink from "./pages/BookingPaymentByLink";
import { CheapFlights } from "./pages/CheapFlights";
import ErrorBoundary from "./pages/ErrorBoundary";
import FAQPage from "./pages/FAQPage";
import { ForBusiness } from "./pages/ForBusiness";
import HomePage from "./pages/HomePage";
import { Hotels } from "./pages/Hotels";
import InfoPageLayout from "./pages/InfoPageLayout";
import { OrderComplete } from "./pages/OrderComplete";
import { OfflineOrderReceived } from "./pages/OfflineOrderConfirmed";
import { OrderError } from "./pages/OrderError";
import PageNotFound from "./pages/PageNotFound";
import RootLayout from "./pages/Root";
import SearchResults from "./pages/SearchResults";
import { TravelInsurance } from "./pages/TravelInsurance";
import WithNavigationLayout from "./pages/WithNavigationLayout";
import { info } from "./pages/info";
import { loader as bookingLoader } from "./pages/loaders/BookingPageLoader";
import { loader as orderOkLoader } from "./pages/loaders/OrderCompleteLoader";
import { loader as orderReceivedLoader } from "./pages/loaders/OrderReceivedLoader";
import { loader as paymentPageLoader } from "./pages/loaders/PaymentByLinkLoader";
import { loader as resultsLoader } from "./pages/loaders/SearchResultsLoader";
import TemporarilyUnavailable from "./pages/TempUnavailable";

const router = createBrowserRouter([
  {
    element: <AuthContextProvider />,
    children: [
      {
        path: "/",
        element: <RootLayout />,
        errorElement: <ErrorBoundary />,
        loader: async () => {
          // Check if localStorage and sessionStorage are available
          const storageAvailable = (type) => {
            let storage;
            try {
              storage = window[type];
              const test = "__storage_test__";
              storage.setItem(test, test);
              storage.removeItem(test);
              return true;
            } catch (e) {
              return false;
            }
          };

          const isLocalStorageAvailable = storageAvailable("localStorage");
          const isSessionStorageAvailable = storageAvailable("sessionStorage");

          return { isLocalStorageAvailable, isSessionStorageAvailable };
        },
        children: [
          {
            element: <WithNavigationLayout />,
            children: [
              { index: true, element: <HomePage /> },
              { path: "cheapflights", element: <CheapFlights /> },
              { path: "hotels", element: <Hotels /> },
              { path: "insurance", element: <TravelInsurance /> },
              { path: "business", element: <ForBusiness /> },
              {
                path: "search/results",
                element: <SearchResults />,
                loader: resultsLoader,
              },
              {
                path: "dashboard",
                element: <TemporarilyUnavailable />,
                children: [
                  { path: "trips", element: <TemporarilyUnavailable /> },
                  { path: "passengers", element: <TemporarilyUnavailable /> },
                  { path: "invoices", element: <TemporarilyUnavailable /> },
                  { path: "alerts", element: <TemporarilyUnavailable /> },
                  { path: "referral", element: <TemporarilyUnavailable /> },
                  { path: "preferences", element: <TemporarilyUnavailable /> },
                ],
              },
              {
                path: "info",
                element: <InfoPageLayout />,
                children: [
                  { path: "terms-of-service", element: <info.TermsOfService /> },
                  { path: "privacy-policy", element: <info.PrivacyPolicy /> },
                  { path: "cancellation-policy", element: <info.CancellationPolicy /> },
                  { path: "guarantee-rules", element: <info.GuaranteeRules /> },
                  { path: "faq", element: <FAQPage /> },
                ],
              },
              { path: "*", element: <PageNotFound /> },
            ],
          },
          {
            path: "booking/cancelled",
            element: <BookingCancelled />,
          },
          {
            path: "booking/payment",
            element: <BookingPaymentByLink />,
            loader: paymentPageLoader,
          },
          {
            id: "bookingRouteController",
            path: "booking",
            element: <BookingFlowController />,
            loader: bookingLoader,
            children: [
              {
                element: <BookingLayoutWrapper />,
                children: [{ path: ":bookingStep", element: <BookingFlowPage /> }],
              },
            ],
          },
          {
            path: "order",
            children: [
              {
                path: ":orderId/thankyou",
                element: <OrderComplete />,
                loader: orderOkLoader,
              },
              {
                path: ":orderId/error",
                element: <OrderError />,
              },
              {
                path: ":orderId/confirmation",
                element: <OfflineOrderReceived />,
                loader: orderReceivedLoader,
              },
              {
                path: ":orderId/complete",
                element: <div>this order has been paid</div>,
              },
            ],
          },
        ],
      },
    ],
  },
]);

export default router;
