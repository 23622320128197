import classes from "./SaleBanners.module.css";
import { Fragment, useState } from "react";
import CarouselIndicatorGroup from "src/components/UI/CarouselIndicators/CarouselIndicatorGroup";
import placeholderImg from "src/assets/img/salePlaceholder.png";
import { customLog } from "src/utils/utils";
import { useDeviceContext } from "src/context/device-context";
import PaddedView from "src/components/UI/PaddedView/PaddedView";

function MobileSaleBanners({ image, currentItem, total, onSwipe }) {
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  const minSwipeLength = 25;

  const onTouchStartHandler = (e) => {
    setTouchEndX(null);
    setTouchStartX(e.targetTouches[0].clientX);
  };

  const onTouchMoveHandler = (e) => setTouchEndX(e.targetTouches[0].clientX);

  const onSwipeHandler = () => {
    const distance = touchStartX - touchEndX;
    const isLeftSwipe = distance > minSwipeLength;
    const isRightSwipe = distance < -minSwipeLength;
    if (isLeftSwipe || isRightSwipe) {
      const swipeDirection = isLeftSwipe ? "left" : "right";
      customLog(swipeDirection);
      onSwipe(swipeDirection);
    }
  };

  return (
    <Fragment>
      <div
        className={`${classes.saleImage} ${classes.container}`}
        onTouchStart={onTouchStartHandler}
        onTouchMove={onTouchMoveHandler}
        onTouchEnd={onSwipeHandler}>
        <a href="/">
          <img alt="sale-banner" src={image} />
        </a>
      </div>
      <CarouselIndicatorGroup count={total} current={currentItem} />
    </Fragment>
  );
}

function DesktopSaleBanners({ items }) {
  return (
    <div className={`${classes.saleImageGroup} ${classes.container}`}>
      {items.map((item) => (
        <a key={item.id} href="/">
          <img alt="sale-banner" src={item.image} />
        </a>
      ))}
    </div>
  );
}

function SaleBanners() {
  const { isMobile } = useDeviceContext();
  const [currentBanner, setCurrentBanner] = useState(0);

  const onSwipeHandler = (direction) => {
    const indexChange = direction === "left" ? 1 : -1;
    let nextBanner = currentBanner + indexChange;
    if (nextBanner > 2) {
      nextBanner = 0;
    } else if (nextBanner < 0) {
      nextBanner = 2;
    }
    customLog("curr banner " + nextBanner);
    setCurrentBanner(nextBanner);
  };

  const saleData = [
    { id: "1", image: require(`src/assets/img/images/pic1.jpg`), link: "/" },
    { id: "2", image: require(`src/assets/img/images/pic2.jpg`), link: "/" },
    { id: "3", image: require(`src/assets/img/images/pic3.jpg`), link: "/" },
  ];

  return (
    <section
      className={`${!isMobile ? classes.desktop : classes.mobile}`}>
      {isMobile ? (
        <MobileSaleBanners
          image={saleData[currentBanner].image}
          currentItem={currentBanner}
          total={saleData.length}
          onSwipe={onSwipeHandler}
        />
      ) : (
        <PaddedView>
          <DesktopSaleBanners items={saleData} />
        </PaddedView>
      )}
    </section>
  );
}

export default SaleBanners;
